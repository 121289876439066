    /*
    * @Description: 变更相关
    * @Version: 1.0
    * @Autor: huliekao
    * @Date: 2021-07-30 15:39:03
    * @LastEditors: Seven
    * @LastEditTime: 2022-10-13 10:16:13
    */
    import axios from "axios";

    const baseUrl = "/qw_new_api";

    //报名新增商品变更时触发校验
    export const addSignUpJudgeRecommend =(data)=>{
      return axios.post(`${baseUrl}/order/biz/recommend/customer/addSignUpJudgeRecommend`,data).then(res=>res.data);
    }


    //获取出纳列表
    export const getSchoolUserCashierVerifiedUser = (params)=>{
      return axios({
        url: `${baseUrl}/order/ty/cashier/getSchoolUserList`,
        method: "get",
        params
      }).then( res=>res.data )
    }


    // 报名新增商品变更时触发校验
    export const getSignUpJudgeRecommend = (data)=> {
      return axios.post(`${baseUrl}/order/biz/recommend/customer/addSignUpJudgeRecommend`,data).then(res=>res.data)
    }

    // 天翼变更申请管理分页列表
    export const getTyChangeList = (data) => {
      return axios.post(`${baseUrl}/order/ty/change/List`, data).then((res) => res.data);
    }
    // 变更天翼订单成交信息招生-销售-转介绍,:orderId:订单编号,type:变更类型
    export const doUpdateOrderDetail = (data) => { // /order/ty/change/updateOrderDetail
      return axios.post(`${baseUrl}/order/ty/change/update`, data).then((res) => res.data);
    }
    // 新增变更 
    export const doOrderUpdateOrderDetail = (data) => { // /order/ty/order/updateOrderDetail
      return axios.post(`${baseUrl}/order/ty/change/add`, data).then((res) => res.data);
    }
    // 获取成交信息订单
    export const doGetOrderDetail = (orderId) => {
      return axios.post(`${baseUrl}/order/ty/order/getOrderDetail?orderId=${orderId}`).then((res) => res.data);
    }

    // 获取订单列表 -------------------------------------------
    export const getOrderListPage = (data) => {
      return axios.post(`${baseUrl}/order/ty/order/orderListPage`, data).then((res) => res.data)
    }
    // 导出订单列表 -------------------------------------------
    export const doOrderExport = (data) => {
      return axios.post(`${baseUrl}/order/ty/order/export`, data).then((res) => res.data)
    }
    // 获取订单售后列表 -------------------------------------------
    export const getOrderAfterSalesList = (data) => {
      return axios.post(`${baseUrl}/order/ty/order/after/sales/list`, data).then((res) => res.data)
    }
    // 导出订单售后列表 -------------------------------------------
    export const doOrderAfterSalesExport = (data) => {
      return axios.post(`${baseUrl}/order/ty/order/after/sales/export`, data).then((res) => res.data)
    }
    // 订单详情更新成交信息 -------------------------------------------
    export const doOrderDetailUpdate = (data) => {
      return axios.post(`${baseUrl}/order/ty/order/orderDetailUpdate`, data).then((res) => res.data)
    } 
    /**
     * 获取NC订单列表 
     * http://10.0.99.9:8081/order/doc.html#/default/nc%E4%BA%A4%E4%BA%92%E6%8E%A5%E5%8F%A3/ncOrderListPageUsingPOST
     */
    export const getNCOrderList = (data) => axios.post(`${baseUrl}/order/nc/orderListPage`, data).then((res) => res.data)
    // 导出列表 -------------------------------------------
    export const doNcEXport = (data) => {
      return axios.post(`${baseUrl}/order/nc/export`, data).then((res) => res.data)
    } 
    /**
     * 获取NC订单详情
     * http://10.0.99.9:8081/order/doc.html#/default/nc%E4%BA%A4%E4%BA%92%E6%8E%A5%E5%8F%A3/getNcOrderDetailUsingPOST
     */
    export const getNcOrderDetail = (data) => axios.post(`${baseUrl}/order/nc/getNcOrderDetail`, data).then((res) => res.data)
    /**
     * 提供全网下单天翼调用接口保存订单成交信息接口
     * http://10.0.99.9:8081/order/doc.html#/default/%E8%AE%A2%E5%8D%95%E6%9C%8D%E5%8A%A1%E5%AF%B9%E5%A4%96%E6%8E%A5%E5%8F%A3/saveOrderDetailToTyUsingPOST
     */ 
      // /order/api/open/v1/ty/order/save
    export const saveTransactionInfo = (data) => axios.post(`${baseUrl}/order/api/open/v1/ty/order/save/detail`, data).then((res) => res.data)

    // 判断转校订单能否进行变更操作
    export const getChangeOperation = (data) => axios.post(`${baseUrl}/order/ty/order/changeOperation`, data).then((res) => res.data)

    // nc订单列表查看号码
    export const getNCsaveOperation = (data) => axios.post(`${baseUrl}/order/nc/saveOperation`, data).then((res) => res.data)

    // 订单售后获取学员信息
    export const getSelectStudentInfo = (id, type) => axios.get(`${baseUrl}/order/ty/order/selectStudentInfo?orderId=${id}&type=${type}`).then((res) => res.data)

    // 根据学习账号或者学员id获取蓝鲸学习信息
    export const getSelectLjUserInfo = (params)=>{
      return axios({
        url: `${baseUrl}/order/ty/order/selectLjUserInfo`,
        method: "get",
        params
      }).then( res=>res.data )
    }

    // 请求蓝鲸新增学习账号，并返回学员id
    export const doSaveLjUser = (data)=>{
      return axios({
        url: `${baseUrl}/order/ty/order/saveLjUser`,
        method: "post",
        data
      }).then( res=>res.data )
    }

    // 退费撤销
    export const doRefundCancel = (data)=>{
      return axios({
        url: `${baseUrl}/order/ty/order/after/sales/refundCancel`,
        method: "post",
        data
      }).then( res=>res.data )
    }

    // 转班撤销
    export const doTurnClassPassedCancel = (data)=>{
      return axios({
        url: `${baseUrl}/order/ty/order/after/sales/turnClassPassedCancel`,
        method: "post",
        data
      }).then( res=>res.data )
    }

    // 报名导入
    export const createOrderByExcel = (data,token)=>{
      return axios({
        url: `/qw_school/schoolcj/order/api/payModule/enrollPlatform/web/createOrderByExcel`,
        method: "post",
        headers:{
          "CrmToken":token,
          "onlineSchoolToken":localStorage.getItem("onlineSchoolToken")
        },
        data
      }).then( res=>res.data )
    }